.container {
  width:100%;

  h2 {
    justify-content: center;
    display: flex;
  }

  .church {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .separator {
      width: 1px;
      background-color: black;
      margin: 5px;
    }
    .location {
      justify-content: center;
      width: 100%;
      text-align: center;
    }
  }
}
