.location {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 32px 0;
    align-items: center;

  .cell{
    font-size: 20px;
    width: 320px;
    align-items: center; 
    margin: 16px 0;
  }

  iframe{
    width: 100%;
    height: 320px;
    border: 0px;
  }

}
