.form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 36px 0;
    align-items: center;

  iframe{
    width: 100%;
    height: 100vh;
    border: 0px;
  }

}
