.container {
  width: 100%;
  background-color: var(--yellowish);
  display: flex;
  justify-content: center;

  .gifts {
    width: var(--myWidth);
    margin-bottom: 48px;

    h2 {
      display: flex;
      justify-content: center;
    }

    .donation {
      margin: 30px;
    }

  }
}
