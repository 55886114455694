:root {
  --sunflower: #ffc41d;
  --garnet: #570008;
  --crimson: #990000;
  --brick: #d8460b;
  --pumpkin: #ff7518;
  --orangish: #c85103;
  --wood: hsl(36, 64%, 64%);
  --brownish: #733702;
  --yellowish: #f2c744;

  --myWidth: 640px;

  font-family: "Average", serif;
  font-weight: 400;
  font-style: normal;

  h1,
  h2,
  h3 {
    font-family: "Caveat", cursive;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
  }

  h1 {
    font-size: 76px;
  }
  h2 {
    font-size: 48px;
  }
  h3{
    font-size: 32px;
  }
  p{
      margin: 0px 10px;
  }
}
