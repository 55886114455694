.container {
  width: 100%;
  display: flex;
  justify-content: center;

  .party {
    width: var(--myWidth);

    h2 {
      display: flex;
      justify-content: center;
    }

    .switch-wrapper {
      margin: 10px 0px;
      width: 100%;

      display: flex;
      justify-content: center;
      .partyLabel {
        margin: 5px 20px;
      }
    }

    .party-info {
      h3 {
        display: flex;
        justify-content: center;
      }
    }
  }
}
